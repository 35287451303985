const employeeFormState = {
    data: {
        full_name: "",
        username: "",
        phone_number: "",
        gender: "",
        role: "pharmacist",
        work_from: "",
        work_to: "",
    },
    errors: {},
};

export default employeeFormState;
